import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useStaticQuery, graphql } from 'gatsby';
import ogImage from '../../assets/og-image.png';

const Head = ({ pageTitle, location, description }) => {

    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                }
            }
        }
    `);

    const { title, keywords } = site.siteMetadata;

    return (
        <Helmet 
            title={`${pageTitle} | ${title}`}
            meta={[
                { name: 'description', content: description },
                { name: 'keywords', content: keywords },
                { 
                    property: `og:title`,
                    content: location && location.pathname === '/' ? 'Welcome to your ReactJS Course' : `${pageTitle} | ${title}`,
                  },
                  {
                    property: `og:type`,
                    content: `website`,
                  },
                  {
                    property: `og:image`,
                    content: `https://www.reactwithdan.com/${ogImage}`,
                  },
            ]}
        />
    );
};

export default Head;